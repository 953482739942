import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { ThemeService as Ng2ChartsThemeService } from 'ng2-charts';
import { ChartOptions } from 'chart.js';

export enum Theme {
  DARK = 'dark',
  LIGHT = 'light',
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private readonly darkStyleClass = 'dark-mode';
  private readonly lightStyleClass = 'light-mode';
  private readonly localStorageFeatureKey = 'theme';
  private _changes$ = new Subject<Theme>();
  private _currentTheme: Theme = this.getCurrentTheme();
  private _currentTheme$ = new BehaviorSubject(this._currentTheme);

  public changes$ = this._changes$.asObservable();
  public currentTheme$ = this._currentTheme$.asObservable();
  public darkThemeIsActive$ = this.currentTheme$.pipe(
    map((t) => t === Theme.DARK),
  );
  public lightThemeIsActive$ = this.currentTheme$.pipe(
    map((t) => t === Theme.LIGHT),
  );

  private get currentTheme(): Theme {
    return this._currentTheme;
  }

  private set currentTheme(theme: Theme) {
    this.document.body.classList.add(
      theme === Theme.DARK ? this.darkStyleClass : this.lightStyleClass,
    );
    this.document.body.classList.remove(
      theme === Theme.DARK ? this.lightStyleClass : this.darkStyleClass,
    );
    localStorage.setItem(this.localStorageFeatureKey, theme);
    this.handleChartTheme(theme);
    this._changes$.next(theme);
    this._currentTheme$.next(theme);
    this._currentTheme = theme;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ng2ChartsThemeService: Ng2ChartsThemeService,
  ) {}

  useDarkTheme(): void {
    this.currentTheme = Theme.DARK;
  }

  useLightTheme(): void {
    this.currentTheme = Theme.LIGHT;
  }

  loadTheme(): void {
    this.currentTheme = this.getCurrentTheme();
  }

  private isTheme(obj: unknown): obj is Theme {
    return obj === Theme.DARK || obj === Theme.LIGHT;
  }

  private getCurrentTheme(): Theme {
    const lsTheme = localStorage.getItem(this.localStorageFeatureKey);
    return this.isTheme(lsTheme) ? lsTheme : Theme.LIGHT;
  }

  private handleChartTheme(theme: Theme): void {
    let overrides: ChartOptions;
    if (theme === Theme.DARK) {
      overrides = {
        scales: {
          x: {
            ticks: { color: 'white' },
            grid: {
              color: 'rgba(255,255,255,0.1)',
            },
          },
          y: {
            ticks: { color: 'white' },
            grid: {
              color: 'rgba(255,255,255,0.1)',
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: 'white',
            },
          },
        },
      };
    } else {
      overrides = {
        scales: undefined,
      };
    }
    this.ng2ChartsThemeService.setColorschemesOptions(overrides);
  }
}
