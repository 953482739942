import { Component, Input } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE_LANG_KEY } from '../../utils/constant';

@Component({
  selector: 'tec-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss'],
})
export class LangSelectorComponent {
  public selectedLang = '';

  @Input()
  public classList = '';

  constructor(private trasnlateService: TranslateService) {
    this.selectedLang = this.trasnlateService.currentLang;
  }

  public get langOptions(): string[] {
    return this.trasnlateService.getLangs();
  }

  public get currentLang(): string {
    return this.trasnlateService.currentLang;
  }

  setLang(ev: MatSelectChange): void {
    localStorage.setItem(LOCAL_STORAGE_LANG_KEY, ev.value);
    this.trasnlateService.use(ev.value);
  }
}
