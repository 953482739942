<header class="grid" [ngClass]="'justify-content-' + justifyContent">
  <div *ngIf="showSearchInput" class="col-12 lg:col-6">
    <mat-form-field #searchField appearance="fill" class="w-full">
      <mat-label>Buscar</mat-label>
      <input
        [(ngModel)]="searchValue"
        (keyup.enter)="search.emit(searchValue)"
        [ngModelOptions]="{ standalone: true }"
        type="text"
        matInput
      />
      <button
        *ngIf="searchValue?.length"
        (click)="clearSearch()"
        mat-icon-button
        type="button"
        matSuffix
      >
        <mat-icon>close</mat-icon>
      </button>
      <button
        (click)="search.emit(searchValue)"
        mat-icon-button
        matSuffix
        type="button"
      >
        <mat-icon> search </mat-icon>
      </button>
    </mat-form-field>
  </div>
  <section class="col-12 md:col-fixed text-left md:w-auto keypad">
    <button
      (click)="downloadExcel.emit($event)"
      [disabled]="loading"
      [style]="{ height: fixedButtonsHeight }"
      *ngIf="showExcelButton"
      class="excel-button mr-2"
      mat-flat-button
      matTooltip="Descargar hojas de cálculos"
    >
      <mat-icon class="mr-1 md:mr-0">backup_table</mat-icon>
      <span class="md:hidden">Descargar hoja de cálculos</span>
    </button>
    <button
      [disabled]="loading"
      [style]="{ height: fixedButtonsHeight }"
      *ngIf="showMoreFiltersButton"
      [matMenuTriggerFor]="filtersMenu"
      class="mr-2"
      color="accent"
      mat-flat-button
      matTooltip="Filtros avanzados"
    >
      <mat-icon class="mr-1 md:mr-0" *ngIf="moreFiltersIcon">{{
        moreFiltersIcon
      }}</mat-icon>
      <span [ngClass]="{ 'md:hidden': moreFiltersIcon }">Filtros</span>
    </button>
    <mat-menu #filtersMenu="matMenu">
      <button (click)="filterBy.emit($event)" mat-menu-item>
        <mat-icon>open_in_new</mat-icon
        >{{ 'table-toolbar.filter-by' | translate }}
      </button>
      <button (click)="turnOffFilters.emit($event)" mat-menu-item>
        <mat-icon>filter_alt_off</mat-icon
        >{{ 'table-toolbar.turn-off-filters' | translate }}
      </button>
    </mat-menu>
    <button
      (click)="add.emit($event)"
      [disabled]="loading"
      [style]="{ height: fixedButtonsHeight }"
      *ngIf="showAddButton"
      color="primary"
      mat-flat-button
    >
      <mat-icon class="mr-1" *ngIf="addIcon">{{ addIcon }}</mat-icon>
      Agregar
    </button>
  </section>
</header>
