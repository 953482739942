import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from "./auditoria.reducers";

export const auditoriaFeatureSelector =
  createFeatureSelector<State>("auditoria");

export const isLoadingSelector = createSelector(
  auditoriaFeatureSelector,
  (state) => state.isLoading
);

export const auditoriasSelector = createSelector(
  auditoriaFeatureSelector,
  (state) => state.auditorias
);

export const errorSelector = createSelector(
  auditoriaFeatureSelector,
  (state) => state.error
);
