import { Component, Inject } from '@angular/core';
import { ConfirmDialogData } from './models';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent implements Required<ConfirmDialogData> {
  public title = '¿Está seguro?';
  public cancelButtonText = 'No';
  public confirmButtonText = 'Sí';
  public description = '';
  constructor(@Inject(MAT_DIALOG_DATA) data?: ConfirmDialogData) {
    if (data) {
      const { title, description, confirmButtonText, cancelButtonText } = data;
      if (title) this.title = title;
      if (description) this.description = description;
      if (confirmButtonText) this.confirmButtonText = confirmButtonText;
      if (cancelButtonText) this.cancelButtonText = cancelButtonText;
    }
  }
}
