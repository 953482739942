import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoDataComponent } from './no-data.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NoDataComponent],

  imports: [CommonModule, MatProgressBarModule, TranslateModule],
  exports: [NoDataComponent],
})
export class NoDataModule {}
