import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BreakpointsService {
  public readonly SM = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--breakpoint-sm');
  public readonly MD = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--breakpoint-md');
  public readonly LG = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--breakpoint-lg');
  public readonly XL = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--breakpoint-xl');

  getValue(breakpoint: 'SM' | 'MD' | 'LG' | 'XL'): number {
    return Number(this[breakpoint].substring(0, this[breakpoint].length - 2));
  }
}
