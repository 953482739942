import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { LoadingService } from '../services/loading/loading.service';
import { Injectable } from '@angular/core';
import { NO_LOADING } from '../http-contexts';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (req.context.has(NO_LOADING)) return next.handle(req);
    this.loadingService.setLoading(true);
    return next
      .handle(req)
      .pipe(finalize(() => this.loadingService.setLoading(false)));
  }
}
