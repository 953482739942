import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
  ChangePasswordPayload,
  LoginPayload,
  LoginResponse,
  SendResetLinkEmailResponse,
} from '../models';
import endpoints from './endpoints';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public apiUrl = environment.apiUrl;
  constructor(private _http: HttpClient) {}

  login(payload: LoginPayload): Observable<LoginResponse> {
    return this._http.post<LoginResponse>(endpoints.login, payload);
  }

  sendResetLinkEmail(email: string): Observable<SendResetLinkEmailResponse> {
    return this._http.post<SendResetLinkEmailResponse>(
      endpoints.sendResetLinkEmail,
      {
        email,
      },
    );
  }

  newPassword(payload: ChangePasswordPayload): Observable<unknown> {
    return this._http.post(endpoints.newPassword, payload);
  }

  logout(): Observable<unknown> {
    return this._http.post(endpoints.logout, {});
  }
}
