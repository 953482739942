import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//MAtERIAL
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
//NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// REDUX
import { routerFeatureKey } from '@store/router';
import { reducers, metaReducers, APP_EFFECTS } from '@store';
import { environment } from '../environments/environment';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared';

import { CoreModule } from './core/core.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LoadingModule } from './shared/components/loading/loading.module';

export function translateHttpLoaderFactory(
  http: HttpClient,
): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const TRANSLATIONS_CONFIG = [
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: translateHttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
];

const NGRX_IMPORTS = [
  StoreModule.forRoot(reducers, {
    metaReducers,
    runtimeChecks: {
      strictActionImmutability: true,
      strictStateImmutability: true,
    },
  }),
  StoreRouterConnectingModule.forRoot({
    stateKey: routerFeatureKey,
    routerState: RouterState.Minimal,
  }),
  EffectsModule.forRoot(APP_EFFECTS),
  StoreDevtoolsModule.instrument({
    name: environment.title,
    logOnly: !environment.production,
  }),
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    CoreModule,
    LoadingModule,
    ...NGRX_IMPORTS,
    ...TRANSLATIONS_CONFIG,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
