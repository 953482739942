import { createReducer, on } from '@ngrx/store';
// import { ILeadState } from '../../models';
import { leadActions as actions } from './lead.actions';
import { ILead } from '../../models';

export const leadFeatureKey = 'lead';

export interface State {
  isLoading: boolean;
  lead: ILead | null;
  id: string | null;
  participantSid: string | null;
  error: string | null;
}
export const initialState: State = {
  isLoading: false,
  lead: null,
  id: null,
  participantSid: null,
  error: null,
};

//     case actions.SET_PACIENTE_LEAD:
//       return {
//         ...state,
//         pacienteLead: null,
//         participantSid: null,
//         status: '',
//       };
//     case actions.UPDATE_ATTRIBUTES_PARTICIPANT:
//       return {
//         ...state,
//         isLoading: true,
//       };
//     case actions.UPDATE_ATTRIBUTES_PARTICIPANT_SUCCESS:
//       return {
//         ...state,
//         isLoading: false,
//         pacienteLead: action.pacienteLead,
//         error: null,
//       };
//     case actions.UPDATE_ATTRIBUTES_PARTICIPANT_FAILURE:
//       return {
//         ...state,
//         isLoading: false,
//         status: 'error',
//         error: action.error,
//       };
//     default:
//       return state;
//   }
// }

export const reducer = createReducer(
  initialState,
  on(actions.getLead, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(actions.getLeadSuccess, (state, { lead }) => ({
    ...state,
    isLoading: false,
    lead: lead,
    error: null,
  })),
  on(actions.getLeadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error: error,
  })),
  on(actions.setLead, () => initialState),
  on(actions.updateLead, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(actions.updateLeadSuccess, (state, { lead }) => ({
    ...state,
    lead: lead,
  })),
  on(actions.updateLeadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    status: 'error',
    error: error,
  })),
);
