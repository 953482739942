import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authFeatureKey, State } from './auth.reducer';
import { RoleId } from '@shared/utils';
import { Role } from 'src/app/dashboard/modules/users/models';

export const selectAuthState = createFeatureSelector<State>(authFeatureKey);
export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state: State) => !!state.user,
);
export const selectAuthUser = createSelector(
  selectAuthState,
  (state: State) => state.user,
);

export const selectAuthUserRoles = createSelector(
  selectAuthState,
  (state: State) => state.user?.role,
);

export const selectAuthUserRolesByName = createSelector(
  selectAuthState,
  (state: State) => {
    const rolesByName = new Map<string, Role>([]);
    (state.user?.role || []).forEach((r) => rolesByName.set(r.name, r));
    return rolesByName;
  },
);

export const selectIsFirstLogin = createSelector(
  selectAuthState,
  (state: State) => !state.user?.iniciado,
);

export const selectUpdatingUser = createSelector(
  selectAuthState,
  (state: State) => state.updatingUser,
);
export const selectToken = createSelector(
  selectAuthState,
  (state: State) => state.token,
);

export const selectError = createSelector(
  selectAuthState,
  (state: State) => state.error,
);

export const selectAuthUserPermissions = createSelector(
  selectAuthState,
  (state: State) => {
    const permissions = new Set<string>([]);
    const permissionObjs = state.user?.permissions || [];

    for (const permission of permissionObjs) {
      permissions.add(permission.name);
    }
    return permissions;
  },
);

export const selectAuthUserPermissionGroups = createSelector(
  selectAuthState,
  (state: State) => {
    const permissionGroups = new Set<string>([]);
    const permissionObjs = state.user?.permissions || [];

    for (const permission of permissionObjs) {
      permissionGroups.add(permission.group);
    }
    return permissionGroups;
  },
);

export const selectAuthUserIsSuperAdmin = createSelector(
  selectAuthState,
  ({ user }: State) =>
    user?.role?.some((r) => r.id === RoleId.SuperAdministrador),
);
