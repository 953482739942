import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { IConversation, IConversationAttributes, IMessage } from '../../models';
import { User } from '../../../users/models';

export const conversationActiveActions = createActionGroup({
  source: 'ConversationActive',
  events: {
    'Set Conversation Active': props<{ conversation: IConversation }>(),
    'Get Messages': props<{ conversation: IConversation }>(),
    'Get Messages Success': props<{ messages: any }>(),
    'Get Messages Failure': props<{ error: string }>(),
    'Add Message': props<{ message: IMessage }>(),
    'Add Media Message': props<{
      media: any;
      message_id: string;
      contentType: string;
    }>(),
    'Send Message': props<{ conversation_id: string; msg: string }>(),
    'Send Message Success': props<{ index: number }>(),
    'Send Message Failure': props<{ error: string }>(),
    'Clear Conversation Active': emptyProps(),
    'Get Conversation Active': props<{ conversation: IConversation }>(),
    'Get Conversation Active Success': props<{ conversation: IConversation }>(),
    'Get Conversation Active Failure': props<{ error: string }>(),
    'Delete Conversation': props<{ id: string }>(),
    'Delete Conversation Success': props<{ id: string }>(),
    'Delete Conversation Failure': props<{ error: string }>(),
    'Update Attributes Conversation': props<{
      id: any;
      attributes: IConversationAttributes;
      userActive?: User;
    }>(),
    'Update Attributes Conversation Success': props<{
      conversation: IConversation;
    }>(),
    'Update Attributes Conversation Failure': props<{ error: string }>(),
  },
});
