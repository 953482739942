import { createActionGroup, props } from '@ngrx/store';
import { IConversation, IMessage, ILead } from '../../models';

export const conversationsActions = createActionGroup({
  source: 'Conversations',
  events: {
    GetConversations: props<{
      eventOn: string;
      emit: { event: string; payload: any };
    }>(),
    'Get Conversations Success': props<{ conversations: IConversation[] }>(),
    'Get Conversations Failure': props<{ error: unknown }>(),
    'Conversation Joined': props<{ conversation: IConversation }>(),
    'Remove Conversation': props<{ conversation_id: string }>(),
    'Set Conversation by Id': props<{ conversation: IConversation }>(),
    'Add Last Message': props<{ message: IMessage }>(),
    'Update Read Last Message': props<{
      conversation_id: string;
      readLastMessage?: boolean;
    }>(),
    'Update Lead': props<{ lead: ILead; conversation_id: string }>(),
    'Set Estado Websocket': props<{ estado: string }>(),
  },
});
