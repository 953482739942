import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonMaterialModule } from './modules/common-material.module';
import { DndDirective } from './directives/dnd/dnd.directive';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ExtendedDirective } from './directives/extended/extended.directive';
import { ConfirmDialogModule } from './components/confirm-dialog/confirm-dialog.module';
import { TableToolbarModule } from './components/table-toolbar/table-toolbar.module';
import { IconOrSpinnerModule } from './components/icon-or-spinner/icon-or-spinner.module';
import { InputWithConfirmationModule } from './components/input-with-confirmation/input-with-confirmation.module';
import { ThemeSwitcherModule } from './components/theme-switcher/theme-switcher.module';
import { PipesModule } from './pipes/pipes.module';
import { PageHeaderModule } from './components/page-header/page-header.module';
import { LangSelectorModule } from './components/lang-selector/lang-selector.module';
import { NoDataModule } from './components/no-data/no-data.module';
import { DialogFormActionButtonsModule } from './components/dialog-form-action-buttons/dialog-form-action-buttons.module';

@NgModule({
  declarations: [DndDirective, ExtendedDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonMaterialModule,
    AngularEditorModule,
  ],
  exports: [
    AngularEditorModule,
    ConfirmDialogModule,
    CommonMaterialModule,
    PageHeaderModule,
    DndDirective,
    ExtendedDirective,
    IconOrSpinnerModule,
    InputWithConfirmationModule,
    PipesModule,
    ReactiveFormsModule,
    TableToolbarModule,
    ThemeSwitcherModule,
    LangSelectorModule,
    NoDataModule,
    DialogFormActionButtonsModule,
  ],
})
export class SharedModule {}
