import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  CreateUserPayload,
  GetUserByFilterParams,
  GetUserPermissionsResponse,
  UpdatePermissionsByUserIdPayload,
  UpdatePermissionsByUserIdResponse,
  UpdatePersonPayload,
  UpdatePersonResponse,
  UpdateUserPayload,
  User,
  VerifyIfTheEmailExistsResponse,
} from '../models';
import { Pagination } from 'src/app/core/models';
import { ChangePasswordForFirstLoginPayload } from 'src/app/auth/models';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private userUrl = '/users';
  private baseUrl = environment.apiUrl;

  constructor(private _http: HttpClient) {}

  private getUrl(url: string): string {
    return this.baseUrl + this.userUrl + url;
  }

  getByFilter(filter: GetUserByFilterParams): Observable<Pagination<User>> {
    return this._http.get<Pagination<User>>(this.getUrl('/get-by-filter'), {
      params: {
        ...filter,
      },
    });
  }

  create(form: CreateUserPayload): Observable<User> {
    return this._http.post<User>(this.getUrl('/create'), { ...form });
  }

  update(id: number, payload: UpdateUserPayload): Observable<User> {
    return this._http.put<User>(this.getUrl(`/update/${id}`), payload);
  }

  updatePerson(form: UpdatePersonPayload): Observable<UpdatePersonResponse> {
    return this._http.put<UpdatePersonResponse>(
      this.getUrl(`/update-person/${form.id}`),
      {
        ...form,
      },
    );
  }

  delete(id: number): Observable<{ data: string }> {
    return this._http.delete<{ data: string }>(this.getUrl(`/delete/${id}`));
  }

  getPermissionsByUserId(
    id: number,
  ): Observable<GetUserPermissionsResponse['data']> {
    return this._http
      .get<GetUserPermissionsResponse>(this.getUrl(`/permisos-for-user/${id}`))
      .pipe(map((r) => r.data));
  }

  updatePermissionsByUserId(
    id: number,
    payload: UpdatePermissionsByUserIdPayload,
  ): Observable<UpdatePermissionsByUserIdResponse> {
    return this._http.put<UpdatePermissionsByUserIdResponse>(
      this.getUrl(`/update-permissions-user/${id}`),
      payload,
    );
  }

  verifyIfTheEmailExists(
    email: string,
  ): Observable<VerifyIfTheEmailExistsResponse> {
    return this._http.get<VerifyIfTheEmailExistsResponse>(
      this.getUrl(`/email-exists/${email}`),
    );
  }

  changePasswordForFirstLogin(
    payload: ChangePasswordForFirstLoginPayload,
  ): Observable<any> {
    return this._http.put(this.getUrl(`/nueva-contraseña`), payload);
  }
}
