import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, leadFeatureKey } from './lead.reducers';

export const getLeadState = createFeatureSelector<State>(leadFeatureKey);

export const isLoadingSelector = createSelector(
  getLeadState,
  (state) => state.isLoading,
);

export const leadSelector = createSelector(getLeadState, (state) => state.lead);

export const leadErrorSelector = createSelector(
  getLeadState,
  (state) => state.error,
);

// export const participantSidSelector = createSelector(
//   getLeadState,
//   (state) => state.participantSid,
// );
