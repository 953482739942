import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { auditoriaActions } from './auditoria.actions';
import { IAuditoria } from '../../models';
import { AuditoriaService } from '../../services/auditoria.service';

@Injectable()
export class AuditoriaEffects {
  createAuditoria$ = createEffect(() =>
    this.actions$.pipe(
      ofType(auditoriaActions.createAuditoria),
      mergeMap((action) => {
        return this.auditoriaService.createAuditoria(action.auditoria).pipe(
          switchMap((result: IAuditoria) => {
            return [
              auditoriaActions.createAuditoriaSuccess({
                new_auditoria: result,
              }),
            ];
          }),
          catchError((error) =>
            of(
              auditoriaActions.createAuditoriaFailure({
                error: error.message,
              }),
            ),
          ),
        );
      }),
    ),
  );

  getAuditoria$ = createEffect(() =>
    this.actions$.pipe(
      ofType(auditoriaActions.getAuditoria),
      mergeMap((action) => {
        return this.auditoriaService
          .getAuditoriaByConversationId(action.conversation_id)
          .pipe(
            map((list) =>
              auditoriaActions.getAuditoriaSuccess({
                auditorias: list.length ? list : null,
              }),
            ),
            catchError((error) =>
              of(
                auditoriaActions.getAuditoriaFailure({
                  error: error.message,
                }),
              ),
            ),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private auditoriaService: AuditoriaService,
  ) {}
}
