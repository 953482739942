import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastService } from '@core/services';
import { authActions } from './auth.actions';
import { UsersService } from 'src/app/dashboard/modules/users/services/users.service';

@Injectable()
export class AuthEffects {
  public login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.login),
      concatMap(({ payload }) =>
        this.authService.login(payload).pipe(
          tap((data) => localStorage.setItem('token', data.access_token)),
          map((data) => authActions.loginSuccess({ data })),
          tap(() => this.router.navigate(['/dashboard'])),
          catchError((error) => {
            this.error.handleError(error);
            return of(authActions.loginFailure({ error }));
          }),
        ),
      ),
    ),
  );

  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logout),
      concatMap(() =>
        this.authService.logout().pipe(
          map(() => authActions.logoutSuccess()),
          catchError(() => of(authActions.logoutFailure())),
        ),
      ),
      tap(() => {
        this.router.navigate(['/auth/login']);
        this.toastService.showToast('Se ha cerrado la sesión', 'success');
      }),
    ),
  );

  public updateAuthenticatedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.updateAuthenticatedUser),
      concatMap(({ payload }) =>
        this.usersService.updatePerson(payload).pipe(
          map(({ data }) =>
            authActions.updateAuthenticatedUserSuccess({ data }),
          ),
          tap(() =>
            this.toastService.showToast(
              '¡Usuario actualizado exitosamente!',
              'success',
            ),
          ),
          catchError((error) => {
            this.error.handleError(error);
            return of(authActions.updateAuthenticatedUserFailure({ error }));
          }),
        ),
      ),
    ),
  );

  public restorePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.restorePassword),
      concatMap(({ payload }) =>
        this.authService.newPassword(payload).pipe(
          map(() => authActions.restorePasswordSuccess()),
          tap(() => {
            this.router.navigate(['/auth/login']);
            this.toastService.showInfo(
              'Su nueva contraseña ha sido registrada, vuelva a iniciar sesión',
            );
          }),
          catchError((error) => {
            this.error.handleError(error);
            return of(authActions.restorePasswordFailure());
          }),
        ),
      ),
    ),
  );
  public changePasswordForFirstLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.changePasswordForFirstLogin),
      concatMap(({ payload }) =>
        this.usersService.changePasswordForFirstLogin(payload).pipe(
          map(() => authActions.changePasswordForFirstLoginSuccess()),
          tap(() => {
            this.router.navigate(['/auth/login']);
            this.toastService.showInfo(
              'Su nueva contraseña ha sido registrada, vuelva a iniciar sesión',
            );
          }),
          catchError((error) => {
            this.error.handleError(error);
            return of(authActions.changePasswordForFirstLoginFailure());
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private error: ErrorHandler,
    private router: Router,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {}
}
