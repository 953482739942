import {
  Action,
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { RouterStateUrl } from '../shared/utils/router';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from 'src/environments/environment';

import { routerFeatureKey } from './router';
import * as authStore from '../auth/store';
import * as conversationsStore from '../dashboard/modules/leads/store/conversations';
import * as conversationActiveStore from '../dashboard/modules/leads/store/conversationActive';
import * as leadStore from '../dashboard/modules/leads/store/lead';
import * as apiStatusStore from '../dashboard/modules/leads/store/apiStatus';
import * as auditoriasStore from '../dashboard/modules/leads/store/auditoria';

import { localStorageSync } from 'ngrx-store-localstorage';

export interface AppState {
  [routerFeatureKey]: RouterReducerState<RouterStateUrl>;
  [authStore.authFeatureKey]: authStore.State;
  [conversationsStore.conversationsFeatureKey]: conversationsStore.State;
  [conversationActiveStore.conversationActiveFeatureKey]: conversationActiveStore.State;
  [leadStore.leadFeatureKey]: leadStore.State;
  [apiStatusStore.apiStatusFeatureKey]: apiStatusStore.Appstate;
  [auditoriasStore.auditoriaFeatureKey]: auditoriasStore.State;
}

export const reducers: ActionReducerMap<AppState> = {
  [routerFeatureKey]: routerReducer,
  [authStore.authFeatureKey]: authStore.reducer,
  [conversationsStore.conversationsFeatureKey]: conversationsStore.reducer,
  [conversationActiveStore.conversationActiveFeatureKey]:
    conversationActiveStore.reducer,
  [leadStore.leadFeatureKey]: leadStore.reducer,
  [apiStatusStore.apiStatusFeatureKey]: apiStatusStore.reducer,
  [auditoriasStore.auditoriaFeatureKey]: auditoriasStore.reducer,
};

export function logger(
  reducer: ActionReducer<AppState>,
): ActionReducer<AppState | undefined> {
  return function (
    state: AppState | undefined,
    action: Action,
  ): AppState | undefined {
    return reducer(state, action);
  };
}

export function localStorageSyncReducer(
  reducer: ActionReducer<AppState>,
): ActionReducer<AppState> {
  return localStorageSync({
    keys: [
      authStore.authFeatureKey,
      conversationsStore.conversationsFeatureKey,
      conversationActiveStore.conversationActiveFeatureKey,
      leadStore.leadFeatureKey,
      apiStatusStore.apiStatusFeatureKey,
      auditoriasStore.auditoriaFeatureKey,
    ],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: MetaReducer[] = !environment.production
  ? [logger, storeFreeze, localStorageSyncReducer]
  : [];

export const APP_EFFECTS = [
  authStore.AuthEffects,
  conversationsStore.ConversationsEffects,
  conversationActiveStore.ConversationActiveEffects,
  leadStore.LeadEffects,
  auditoriasStore.AuditoriaEffects,
];
