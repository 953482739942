import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State, conversationActiveFeatureKey } from "./conversationActive.reducers";

export const getConversationActiveState =
  createFeatureSelector<State>(conversationActiveFeatureKey);

export const isLoadingConversationActiveSelector = createSelector(
  getConversationActiveState, // selectFeature,
  (state) => state.isLoading
);

export const conversationActiveSelector = createSelector(
  getConversationActiveState,
  (state) => state.conversation
);

export const conversationActiveErrorSelector = createSelector(
  getConversationActiveState,
  (state) => state.error
);

export const messagesSelector = createSelector(
  getConversationActiveState,
  (state) => state.messages
);
