import { ValidationErrors } from '@angular/forms';
import {
  MaxFileSizeExceededValidationError,
  MaxFileSizeExceededValidationErrorProps,
} from './max-file-size-exceeded-validation-error.class';
import {
  PasswordMustMatchValidationError,
  PasswordMustMatchValidationErrorProps,
} from './passwords-must-match-validation-error-builder.class';
import {
  NoSpecialCharactersValidationError,
  NoSpecialCharactersValidationErrorProps,
} from './no-special-characters-validation-error.class';
import {
  OnlyNumericValidationError,
  OnlyNumericValidationErrorProps,
} from './only-numeric-validation-error.class';
import {
  OnlyAlphabeticValidationError,
  OnlyAlphabeticValidationErrorProps,
} from './only-alphabetic-validation-error.class';
import {
  EmailValidationError,
  EmailValidationErrorProps,
} from './email-validation-error.class';
import {
  EmailAlreadyRegisteredValidationError,
  EmailAlreadyRegisteredValidationErrorProps,
} from './email-already-registered-validation-error.class';

export class CustomValidationErrorBuilder {
  maxFileSizeExceeded({
    fileSize,
    maxSize,
  }: MaxFileSizeExceededValidationErrorProps): ValidationErrors {
    return {
      [MaxFileSizeExceededValidationError.name]:
        new MaxFileSizeExceededValidationError(fileSize, maxSize),
    };
  }
  passwordsMustMatch({
    passwordControl,
    confirmationControl,
  }: PasswordMustMatchValidationErrorProps): ValidationErrors {
    return {
      [PasswordMustMatchValidationError.name]:
        new PasswordMustMatchValidationError(
          passwordControl,
          confirmationControl,
        ),
    };
  }

  noSpecialCharactersValidationError({
    regex,
  }: NoSpecialCharactersValidationErrorProps): ValidationErrors {
    return {
      [NoSpecialCharactersValidationError.name]:
        new NoSpecialCharactersValidationError(regex),
    };
  }

  onlyNumericValitacionError({
    regex,
  }: OnlyNumericValidationErrorProps): ValidationErrors {
    return {
      [OnlyNumericValidationError.name]: new OnlyNumericValidationError(regex),
    };
  }

  onlyAlphabeticValidationError({
    regex,
  }: OnlyAlphabeticValidationErrorProps): ValidationErrors {
    return {
      [OnlyAlphabeticValidationError.name]: new OnlyAlphabeticValidationError(
        regex,
      ),
    };
  }

  emailValidationError({ regex }: EmailValidationErrorProps): ValidationErrors {
    return {
      [EmailValidationError.name]: new EmailValidationError(regex),
    };
  }

  emailAlreadyRegisteredValidationError({
    value,
  }: EmailAlreadyRegisteredValidationErrorProps): ValidationErrors {
    return {
      [EmailAlreadyRegisteredValidationError.name]:
        new EmailAlreadyRegisteredValidationError(value),
    };
  }
}
