import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { leadActions } from './lead.actions';
import { conversationsActions } from '../conversations/conversations.actions';

import { ILead } from '../../models';
import { WebSocketService } from 'src/app/core/services/websocket/websocket.service';
import { AppState } from '@store';
import { apiStatusActions } from '../apiStatus';
import { auditoriaActions } from '../auditoria';

@Injectable()
export class LeadEffects {
  updateLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(leadActions.updateLead),
      mergeMap((action) => {
        // this.appStore.dispatch(
        //   setAPIStatus({
        //     apiStatus: { apiResponseMessage: '', apiStatus: '' },
        //   }),
        // );
        this.wsService.emit('updateLeadWS', action.attributes);
        return this.wsService.listen('leadUpdated').pipe(
          switchMap((resp: ILead) => {
            this.appStore.dispatch(
              apiStatusActions.setAPIStatus({
                apiStatus: { apiResponseMessage: '', apiStatus: 'success' },
              }),
            );
            return [
              leadActions.updateLeadSuccess({
                lead: resp,
              }),
              conversationsActions.updateLead({
                lead: resp,
                conversation_id: action.conversation_id,
              }),
              auditoriaActions.createAuditoria({
                auditoria: {
                  detalle: `${action.userActive?.person.name} ${action.userActive?.person.last_name} actualiza la información del participante.`,
                  conversation_id: action.conversation_id,
                },
              }),
            ];
          }),
          catchError((error) => {
            apiStatusActions.setAPIStatus({
              apiStatus: { apiResponseMessage: '', apiStatus: 'error' },
            });
            return of(
              leadActions.updateLeadFailure({
                error: error.message,
              }),
            );
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private wsService: WebSocketService,
    private appStore: Store<AppState>,
  ) {}
}
