import { Pipe, PipeTransform } from '@angular/core';
import {
  MaxFileSizeExceededValidationError,
  PasswordMustMatchValidationError,
} from 'src/app/core/classes';
import { EmailAlreadyRegisteredValidationError } from 'src/app/core/classes/email-already-registered-validation-error.class';
import { EmailValidationError } from 'src/app/core/classes/email-validation-error.class';
import { NoSpecialCharactersValidationError } from 'src/app/core/classes/no-special-characters-validation-error.class';
import { OnlyAlphabeticValidationError } from 'src/app/core/classes/only-alphabetic-validation-error.class';
import { OnlyNumericValidationError } from 'src/app/core/classes/only-numeric-validation-error.class';

export const handledErrorStartsWithKey = 'errorHandled_';

@Pipe({
  name: 'controlErrorMessage',
})
export class ControlErrorMessagePipe implements PipeTransform {
  transform(controlError: { key: string; value: unknown }): string {
    const nativeErrors: Record<string, string> = {
      required: 'control-error-message.required',
      maxlength: 'control-error-message.maxlength',
      minlength: 'control-error-message.minlength',
      max: 'control-error-message.max.',
      min: 'control-error-message.min.',
      pattern: 'control-error-message.pattern',
      email: 'control-error-message.email',
    };

    const customErrors: Record<string, string> = {
      [EmailValidationError.name]: 'control-error-message.email',
      [EmailAlreadyRegisteredValidationError.name]:
        'control-error-message.email-already-registered',
      [OnlyAlphabeticValidationError.name]:
        'control-error-message.only-alphabetic',
      [OnlyNumericValidationError.name]: 'control-error-message.only-numeric',
      [NoSpecialCharactersValidationError.name]:
        'control-error-message.no-special-characters',
      [PasswordMustMatchValidationError.name]:
        'control-error-message.passwords-must-match',
      [MaxFileSizeExceededValidationError.name]:
        'control-error-message.max-file-size-exceeded',
    };

    let errorMessage =
      nativeErrors[controlError.key] ||
      customErrors[controlError.key] ||
      'control-error-message.invalid-field';

    if (controlError.key?.startsWith(handledErrorStartsWithKey)) {
      errorMessage = controlError.value as string;
    }

    if (controlError.value instanceof MaxFileSizeExceededValidationError) {
      controlError.value = {
        ...controlError.value,
        maxSize: controlError.value.getUnitConvertionOf('maxSize', 'mb'),
      };
    }

    return errorMessage;
  }
}
