import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuditoria } from '../models';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuditoriaService {
  private baseUrl = environment.apiUrl;

  constructor(private _http: HttpClient) {}

  private getUrl(url: string): string {
    return this.baseUrl + url;
  }

  getAuditoriaByConversationId(id: string): Observable<IAuditoria[]> {
    return this._http.get<IAuditoria[]>(this.getUrl(`/get-auditoria/${id}`));
  }

  createAuditoria(data: any): Observable<any> {
    return this._http.post(this.getUrl('/create-auditoria'), { ...data });
  }
}
