import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, switchMap } from 'rxjs/operators';
import { conversationActiveActions } from './conversationActive.actions';
import { leadActions } from '../lead/lead.actions';
import { conversationsActions } from '../conversations/conversations.actions';

import { WebSocketService } from 'src/app/core/services/websocket/websocket.service';
import { auditoriaActions } from '../auditoria/auditoria.actions';

@Injectable()
export class ConversationActiveEffects {
  getConversationActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationActiveActions.getConversationActive),
      mergeMap((action) => {
        return [
          conversationActiveActions.getConversationActiveSuccess({
            conversation: action.conversation,
          }),
          conversationActiveActions.getMessages({
            conversation: action.conversation,
          }),
          leadActions.getLeadSuccess({
            lead: action.conversation.lead,
          }),
          // AppActions.getPacienteLead({
          //   conversation: action.conversation,
          // }),
          // AppActions.getAuditoria({
          //   conversation_id: action.conversation._id,
          // }),
        ];
      }),
    ),
  );

  getMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationActiveActions.getMessages),
      mergeMap((action) => {
        this.wsService.emit('getMessages', { _id: action.conversation._id });
        return this.wsService.listen('loadMessages').pipe(
          switchMap((data: any) => {
            return [
              conversationActiveActions.getMessagesSuccess({ messages: data }),
            ];
          }),
        );
      }),
    ),
  );

  deleteConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationActiveActions.deleteConversation),
      mergeMap((action) => {
        this.wsService.emit('deleteConversation', {
          id: action.id,
        });

        return this.wsService.listen('conversationLeft').pipe(
          switchMap((resp: any) => {
            debugger;
            return [
              conversationsActions.removeConversation({
                conversation_id: resp.id,
              }),
            ];
          }),
        );
      }),
    ),
  );

  updateAttributesConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationActiveActions.updateAttributesConversation),
      mergeMap((action) => {
        this.wsService.emit('updateConversation', {
          ...action.attributes,
          id: action.id,
        });
        return this.wsService.listen('conversationUpdated').pipe(
          switchMap((data: any) => {
            let array_actions: any[] = [
              conversationActiveActions.updateAttributesConversationSuccess({
                conversation: data,
              }),
              conversationsActions.setConversationById({ conversation: data }),
            ];
            if (
              action.attributes &&
              action.attributes.unreadMessages == false
            ) {
              array_actions.push(
                conversationsActions.updateReadLastMessage({
                  conversation_id: data._id,
                  readLastMessage: action.attributes.unreadMessages,
                }),
              );
            } else {
              array_actions.push(
                auditoriaActions.createAuditoria({
                  auditoria: {
                    detalle: `${action.userActive?.person?.name} ${action.userActive?.person?.last_name} actualiza los atributos de la conversación`,
                    conversation_id: data._id,
                  },
                }),
              );
            }
            return array_actions;
          }),
        );
      }),
    ),
  );

  constructor(private actions$: Actions, private wsService: WebSocketService) {}
}
