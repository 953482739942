import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State, conversationsFeatureKey } from "./conversations.reducers";

export const getConversationsState = createFeatureSelector<State>(conversationsFeatureKey);

export const isLoadingConversationsSelector = createSelector(
  getConversationsState, // selectFeature,
  (state) => state.isLoading
);

export const conversationsSelector = createSelector(
  getConversationsState,
  (state) => state.conversations
);

export const conversationsErrorSelector = createSelector(
  getConversationsState,
  (state) => state.error
);

export const estadoSelector = createSelector(
  getConversationsState,
  (state) => state.estado
);
