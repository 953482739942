import { Component } from '@angular/core';
import { LoadingService } from '@core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'tec-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  public loading$: Observable<boolean>;
  constructor(private loadingService: LoadingService) {
    this.loading$ = this.loadingService.loading$;
  }
}
