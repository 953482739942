import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputWithConfirmationComponent } from './input-with-confirmation.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IconOrSpinnerModule } from '../icon-or-spinner/icon-or-spinner.module';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMaterialModule } from '../../modules/common-material.module';

@NgModule({
  declarations: [InputWithConfirmationComponent],
  imports: [
    CommonModule,
    CommonMaterialModule,
    MatIconModule,
    PipesModule,
    TranslateModule,
    IconOrSpinnerModule,
    ReactiveFormsModule,
  ],
  exports: [InputWithConfirmationComponent],
})
export class InputWithConfirmationModule {}
