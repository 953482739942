import { Nullable } from '@shared/utils';
import { IAuditoria } from '../../models';
import { auditoriaActions } from './auditoria.actions';
import { createReducer, on } from '@ngrx/store';

export const auditoriaFeatureKey = 'auditoria';

export interface State {
  isLoading: boolean;
  error: Nullable<string>;
  auditorias: Nullable<IAuditoria[]>;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  auditorias: null,
};

export const reducer = createReducer(
  initialState,
  on(auditoriaActions.getAuditoria, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(auditoriaActions.getAuditoriaSuccess, (state, { auditorias }) => ({
    ...state,
    isLoading: false,
    auditorias: auditorias && auditorias.length > 0 ? auditorias : null,
  })),
  on(auditoriaActions.getAuditoriaFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error: error,
  })),
  on(auditoriaActions.createAuditoria, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(auditoriaActions.createAuditoriaSuccess, (state, { new_auditoria }) => {
    let copy_auditorias = Object.assign([], state.auditorias, new_auditoria);

    return {
      ...state,
      isLoading: false,
      error: null,
      auditorias: copy_auditorias,
    };
  }),
  on(auditoriaActions.createAuditoriaFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error: error,
  })),
  on(auditoriaActions.clearAuditorias, () => initialState),
);
