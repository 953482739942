import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogFormActionButtonsComponent } from './dialog-form-action-buttons.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DialogFormActionButtonsComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule, TranslateModule],
  exports: [DialogFormActionButtonsComponent],
})
export class DialogFormActionButtonsModule {}
