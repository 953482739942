import { createReducer, on } from '@ngrx/store';
import { Nullable } from '@shared/utils';
import { Token } from '../models';
import { authActions } from './auth.actions';
import { User } from 'src/app/dashboard/modules/users/models';

export const authFeatureKey = 'auth';

export interface State {
  updatingUser: boolean;
  loggingIn: boolean;
  user: Nullable<User>;
  token: Nullable<Token>;
  error: unknown;
}

export const authInitialState: State = {
  updatingUser: false,
  loggingIn: false,
  user: null,
  token: null,
  error: null,
};

export const reducer = createReducer(
  authInitialState,

  // LOGIN

  on(authActions.login, (state) => ({
    ...state,
    loggingIn: true,
  })),
  on(
    authActions.loginSuccess,
    (state, { data: { user, access_token, token_type } }) => ({
      ...state,
      loggingIn: false,
      user,
      error: null,
      token: {
        access_token,
        token_type,
      },
    }),
  ),
  on(authActions.loginFailure, (state, { error }) => ({
    ...state,
    error,
    loggingIn: false,
  })),

  // LOGOUT

  on(authActions.logout, (state) => state),
  on(authActions.logoutSuccess, () => authInitialState),
  on(authActions.logoutFailure, () => authInitialState),

  // UPDATE AUTH USER
  on(authActions.updateAuthenticatedUser, (state) => ({
    ...state,
    updatingUser: true,
  })),
  on(authActions.updateAuthenticatedUserSuccess, (state, { data }) => ({
    ...state,
    user: state.user
      ? { ...state.user, person: { ...state.user.person, ...data } }
      : null,
    updatingUser: false,
  })),
  on(authActions.updateAuthenticatedUserFailure, (state) => ({
    ...state,
    updatingUser: false,
  })),
  on(authActions.restorePassword, (state) => state),
  on(authActions.restorePasswordSuccess, () => authInitialState),
  on(authActions.restorePasswordFailure, () => authInitialState),
  on(authActions.changePasswordForFirstLogin, (state) => state),
  on(authActions.changePasswordForFirstLoginSuccess, () => authInitialState),
  on(authActions.changePasswordForFirstLoginFailure, () => authInitialState),
);
