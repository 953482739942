import { createReducer, on } from '@ngrx/store';
import { apiStatusActions } from './appState.actions';

export const apiStatusFeatureKey = 'apiStatus';

export interface Appstate {
  apiStatus: string;
  apiResponseMessage: string;
}

export const appInitialState: Readonly<Appstate> = {
  apiResponseMessage: '',
  apiStatus: '',
};

export const reducer = createReducer(
  appInitialState,
  on(apiStatusActions.setAPIStatus, (state, { apiStatus }) => ({
    ...state,
    ...apiStatus,
  })),
);

// export function reducer(state: Appstate = appInitialState, action) {
//   switch (action.type) {
//     case API_STATUS:
//       return {
//         ...state,
//         ...action.apiStatus,
//       };
//     default:
//       return state;
//   }
// }
