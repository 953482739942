import { FormControl } from '@angular/forms';

export interface PasswordMustMatchValidationErrorProps {
  passwordControl: FormControl;
  confirmationControl: FormControl;
}

export class PasswordMustMatchValidationError
  implements PasswordMustMatchValidationErrorProps
{
  constructor(
    public passwordControl: FormControl,
    public confirmationControl: FormControl,
  ) {}
}
