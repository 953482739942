import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from './page-header.component';
import { PipesModule } from '../../pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';
import { CommonMaterialModule } from '../../modules/common-material.module';

@NgModule({
  declarations: [PageHeaderComponent],
  imports: [
    CommonModule,
    PipesModule,
    CommonMaterialModule,
    RouterModule,
  ],
  exports: [PageHeaderComponent],
})
export class PageHeaderModule {}
