<div class="lang-selector" [ngClass]="classList">
  <mat-select
    class="w-full"
    [ngModel]="currentLang"
    (selectionChange)="setLang($event)"
  >
    <mat-option *ngFor="let lang of langOptions" [value]="lang">
      <!-- https://www.flaticon.com/packs/countrys-flags -->
      <div style="width: 500px">
        <img
          class="mr-2"
          height="20"
          width="20"
          [src]="'../../../../assets/icon/' + lang + '.png'"
        /><span>{{ lang | uppercase }}</span>
      </div>
    </mat-option>
  </mat-select>
</div>
