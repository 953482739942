import { Component, Input } from '@angular/core';

@Component({
  selector: 'tec-no-data',
  templateUrl: './no-data.component.html',
  styles: [],
})
export class NoDataComponent {
  @Input()
  public loading = false;
}
