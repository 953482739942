import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
// import { WebSocketService } from "@core/services";
import { conversationsActions } from "./conversations.actions";
import { IConversation } from "../../models";
import { WebSocketService } from "src/app/core/services/websocket/websocket.service";

@Injectable()
export class ConversationsEffects {
  public getConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conversationsActions.getConversations),
      mergeMap((action) => {
        this.wsService.emit(action.emit.event, action.emit.payload);

        return this.wsService.listen(action.eventOn).pipe(
          map((data: { conversations: IConversation[]; total: number }) => {
            console.log("conversations: ", data);
            return conversationsActions.getConversationsSuccess({
              conversations: data.conversations,
            });
          }),
          catchError((error) =>
            of(
              conversationsActions.getConversationsFailure({
                error: error.message,
              })
            )
          )
        );
      })
    )
  );

  constructor(private actions$: Actions, private wsService: WebSocketService) {}
}
