import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { BreakpointsService } from '@shared/utils';

@Component({
  selector: 'tec-table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss'],
})
export class TableToolbarComponent implements AfterViewInit {
  /**
   * Esta propiedad determina la alineación entre el grupo de botones y el input de filtro global.
   * @default end
   * @public
   */
  @Input()
  public justifyContent: 'end' | 'between' | 'start' = 'end';

  @Input()
  public showExcelButton = false;

  @Output()
  public downloadExcel = new EventEmitter<PointerEvent>();

  /**
   * Esta propiedad determina si actualmente está ocurriendo una acción asíncrona. Lo cual bloquea los filtros y el botón Agregar.
   * @public
   */
  @Input()
  public loading = false;

  /**
   * Esta propiedad determina si debe mostrarse o no el boton Agregar.
   * @public
   */
  @Input()
  public showAddButton = true;

  /**
   * Esta propiedad determina el icono que se mostrará dentro del boton Agregar, debe ser una key de Material Icons.
   * {@link https://fonts.google.com/icons}
   * @public
   */
  @Input()
  public addIcon = 'add';

  /**
   * Esta propiedad determina si debe mostrarse o no el botón de filtros avanzados.
   * @public
   */
  @Input()
  public showMoreFiltersButton = true;

  /**
   * Esta propiedad determina el icono que se mostrará dentro del boton de filtros, debe ser una key de Material Icons.
   * {@link https://fonts.google.com/icons}
   * @public
   */
  @Input()
  public moreFiltersIcon = 'filter_alt';

  /**
   * Este evento se dispara al cliquear el botón Agregar de la toolbar.
   * @public
   */
  @Output()
  public add = new EventEmitter<PointerEvent>();

  @Input()
  public searchValue = '';

  @Input()
  public showSearchInput = true;

  @Output()
  public searchValueChange = new EventEmitter<string>();

  @Output()
  public search = new EventEmitter<string>();

  @ViewChild('searchField')
  public searchField?: MatFormField;

  @Output()
  public filterBy = new EventEmitter<MouseEvent>();

  @Output()
  public turnOffFilters = new EventEmitter<MouseEvent>();

  public globalFilterFieldOffsetHeight?: number;

  @HostListener('window:resize')
  private onWindowResize(): void {
    this.setButtonsHeight();
  }

  public fixedButtonsHeight = '';

  constructor(private breakpoints: BreakpointsService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setButtonsHeight();
    });
  }

  clearSearch(): void {
    this.searchValue = '';
    this.searchValueChange.emit(this.searchValue);
    this.search.emit(this.searchValue);
  }

  private setButtonsHeight(): void {
    if (window.innerWidth > this.breakpoints.getValue('MD')) {
      this.fixedButtonsHeight =
        this.searchField?._textField.nativeElement.offsetHeight + 'px';
    } else {
      this.fixedButtonsHeight = '';
    }
  }
}
