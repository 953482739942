import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  ErrorInterceptor,
  LoadingInterceptor,
  TokenInterceptor,
} from './intenceptors';
import { GlobalErrorHandler } from './error-handlers';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MyCustomPaginatorIntl } from './services/my-custom-paginator-intl/my-custom-paginator-intl.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
} from '@angular/material/dialog';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';

const CUSTOM_MATERIAL_SETTINGS = [
  { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 6000 } },
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: { appearance: 'outline' },
  },
  { provide: MAT_DATE_LOCALE, useValue: 'es-VE' },
  {
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: { displayDefaultIndicatorType: false },
  },
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      ...new MatDialogConfig(),
    },
  },
];

@NgModule({
  declarations: [],
  imports: [TranslateModule, HighlightModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        lineNumbersLoader: () => import('ngx-highlightjs/line-numbers'),
        themePath: 'node_modules/highlight.js/styles/androidstudio.css',
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          // Opcional: Añade los lenguajes de programación que desees utilizar.
          // Por ejemplo, si quieres resaltar código JavaScript:
          // javascript: () => import('highlight.js/lib/languages/javascript'),
        },
      },
    },
    ...CUSTOM_MATERIAL_SETTINGS,
  ],
  exports: [MatSnackBarModule],
})
export class CoreModule {}
