import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableToolbarComponent } from './table-toolbar.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CommonMaterialModule } from '../../modules/common-material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TableToolbarComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    CommonMaterialModule,
    TranslateModule,
  ],
  exports: [TableToolbarComponent],
})
export class TableToolbarModule {}
