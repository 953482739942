import { createReducer, on } from '@ngrx/store';
import { IConversation, IMessage } from '../../models';
import { conversationActiveActions as actions } from './conversationActive.actions';

export const conversationActiveFeatureKey = 'conversationActive';
export interface State {
  isLoading: boolean;
  conversation: IConversation | null;
  messages: IMessage[] | null;
  media: any[] | null;
  error: string | null;
}
const initialState: State = {
  isLoading: false,
  conversation: null,
  messages: [],
  media: [],
  error: null,
};
export const reducer = createReducer(
  initialState,

  on(actions.setConversationActive, (state, { conversation }) => ({
    ...state,
    isLoading: true,
    conversation: conversation
  })),
  on(actions.getMessages, (state) => {
    scrollChat();
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(actions.getMessagesSuccess, (state, { messages }) => {
    scrollChat();
    return {
      ...state,
      isLoading: false,
      messages: messages,
    };
  }),
  on(actions.getMessagesFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(actions.sendMessage, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(actions.sendMessageFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error: error,
  })),
  on(actions.clearConversationActive, () => initialState),
  on(actions.getConversationActive, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(actions.getConversationActiveSuccess, (state, { conversation }) => ({
    ...state,
    conversation: conversation,
    isLoading: false,
  })),
  on(actions.getConversationActiveFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(actions.deleteConversation, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(actions.deleteConversationSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(actions.deleteConversationFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(actions.updateAttributesConversation, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    actions.updateAttributesConversationSuccess,
    (state, { conversation }) => ({
      ...state,
      conversation: getAttributesConversation(conversation),
      isLoading: false,
    }),
  ),
  on(actions.updateAttributesConversationFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(actions.addMessage, (state, { message }) => {
    let messages_copy: IMessage[] = Object.assign([], state.messages);
    const index_msg = messages_copy.findIndex(
      (msg: IMessage) => msg._id === message._id,
    );
    if (index_msg !== -1) {
      messages_copy[index_msg] = message;
    } else {
      if (
        state.conversation &&
        state.conversation._id === message.conversation._id
      ) {
        messages_copy.push(message);
      }
    }
    if (state.conversation) scrollChat();
    return {
      ...state,
      messages: messages_copy,
      isLoading: false,
    };
  }),
  on(actions.addMediaMessage, (state, { media, message_id, contentType }) => {
    let _newMedia: any[] = [];
    for (const item of media) {
      _newMedia.push({
        message_id: message_id,
        media_id: item[0],
        contentType: contentType,
        url: item[1],
      });
    }
    return {
      ...state,
      messages: state.messages
        ? state.messages.map((_m) =>
            _m._id === message_id ? { ..._m, media: _newMedia } : _m,
          )
        : null,
    };
  }),
);

const getAttributesConversation = (_conversation: any) => {
  let att: IConversation = {
    ..._conversation,
    _id: _conversation._id,
    friendlyName: _conversation.friendlyName,
    lastMessage: _conversation.lastMessage,
    messages: [],
  };

  return att;
};

const scrollChat = (): void => {
  setTimeout(() => {
    let messageBody = document.querySelector('.direct-chat-messages');
    if (messageBody)
      messageBody.scrollTop =
        messageBody.scrollHeight - messageBody.clientHeight;
  }, 900);
};
