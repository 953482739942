import { Component, Input } from '@angular/core';

@Component({
  selector: 'tec-icon-or-spinner',
  templateUrl: './icon-or-spinner.component.html',
})
export class IconOrSpinnerComponent {
  @Input()
  public spinnerIf = false;
}
