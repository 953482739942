import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ChangePasswordForFirstLoginPayload,
  ChangePasswordPayload,
  LoginPayload,
  LoginResponse,
} from '../models';
import {
  UpdatePersonPayload,
  UpdatePersonResponse,
} from 'src/app/dashboard/modules/users/models';

export const authActions = createActionGroup({
  source: 'Auth',
  events: {
    Login: props<{ payload: LoginPayload }>(),
    'Login Success': props<{ data: LoginResponse }>(),
    'Login Failure': props<{ error: unknown }>(),
    Logout: emptyProps(),
    'Logout Success': emptyProps(),
    'Logout Failure': emptyProps(),
    'Update Authenticated User': props<{ payload: UpdatePersonPayload }>(),
    'Update Authenticated User Success': props<{
      data: UpdatePersonResponse['data'];
    }>(),
    'Update Authenticated User Failure': props<{ error: unknown }>(),
    'Restore Password': props<{ payload: ChangePasswordPayload }>(),
    'Restore Password Success': emptyProps(),
    'Restore Password Failure': emptyProps(),
    'Change Password For First Login': props<{
      payload: ChangePasswordForFirstLoginPayload;
    }>(),
    'Change Password For First Login Success': emptyProps(),
    'Change Password For First Login Failure': emptyProps(),
  },
});
