import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject, startWith } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  public changes = new Subject<void>();

  public firstPageLabel = `Primera página`;
  public itemsPerPageLabel = `Items por página:`;
  public lastPageLabel = `Última página`;
  public nextPageLabel = 'Página siguiente';
  public previousPageLabel = 'Página anterior';

  public getRangeLabel = (
    page: number,
    pageSize: number,
    length: number,
  ): string => {
    if (length === 0) {
      return `Página 1 de 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Página ${page + 1} de ${amountPages}`;
  };

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.pipe(startWith(1)).subscribe({
      next: () => {
        this.firstPageLabel = this.translateService.instant(
          'paginator-intl.first-page-label',
        );
        this.itemsPerPageLabel = this.translateService.instant(
          'paginator-intl.items-per-page-label',
        );
        this.lastPageLabel = this.translateService.instant(
          'paginator-intl.last-page-label',
        );
        this.nextPageLabel = this.translateService.instant(
          'paginator-intl.next-page-label',
        );
        this.previousPageLabel = this.translateService.instant(
          'paginator-intl.previous-page-label',
        );
        this.getRangeLabel = (
          page: number,
          pageSize: number,
          length: number,
        ): string => {
          if (length === 0) {
            return this.translateService.instant(
              'paginator-get-range-label-empty',
            );
          }
          const amountPages = Math.ceil(length / pageSize);
          return this.translateService.instant('paginator-get-range-label', {
            page: page + 1,
            amountPages,
          });
        };

        this.changes.next();
      },
    });
  }
}
