import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ThemeService } from './core/services/theme/theme.service';
import { Nullable } from './shared/utils/types';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE_LANG_KEY } from './shared/utils/constant';

@Component({
  selector: 'app-root',
  template: `<tec-loading></tec-loading>
    <router-outlet></router-outlet> `,
})
export class AppComponent {
  public title = '';
  public favIcon: Nullable<HTMLLinkElement> =
    document.querySelector('#favIcon') || null;
  constructor(
    private themeService: ThemeService,
    private titleService: Title,
    private translateService: TranslateService,
  ) {
    this.themeService.loadTheme();
    this.titleService.setTitle(environment.title);
    if (this.favIcon) {
      this.favIcon.href = `../assets/images/${environment.favicon}`;
    }
    this.loadLangs();
  }

  loadLangs(): void {
    const storageLang = localStorage.getItem(LOCAL_STORAGE_LANG_KEY);
    this.translateService.addLangs(['es', 'en']);
    this.translateService.setDefaultLang('es');
    this.translateService.use(storageLang || this.translateService.defaultLang);
  }
}
