import { createReducer, on } from '@ngrx/store';
import { IConversation, IMessage } from '../../models';
import { conversationsActions } from './conversations.actions';

export const conversationsFeatureKey = 'conversations';

export interface State {
  isLoading: boolean;
  conversations: IConversation[];
  error: unknown;
  estado: string;
}
export const initialConversationsState: State = {
  isLoading: false,
  conversations: [],
  error: null,
  estado: '',
};

export const reducer = createReducer(
  initialConversationsState,
  on(conversationsActions.getConversations, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    conversationsActions.getConversationsSuccess,
    (state, { conversations }) => ({
      ...state,
      isLoading: false,
      conversations: conversations,
      error: null,
    }),
  ),
  on(conversationsActions.getConversationsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error: error,
  })),
  on(conversationsActions.conversationJoined, (state, { conversation }) => ({
    ...state,
    conversations: state.conversations
      ? [conversation, ...state.conversations]
      : [],
  })),
  on(conversationsActions.removeConversation, (state, { conversation_id }) => ({
    ...state,
    conversations: state.conversations
      ? state.conversations.filter((_c) => _c._id !== conversation_id)
      : [],
  })),
  on(conversationsActions.setConversationById, (state, { conversation }) => ({
    ...state,
    conversations: state.conversations
      ? state.conversations.map((c) => {
          return c._id == conversation._id ? conversation : c;
        })
      : [],
    friendlyname: conversation.friendlyName,
  })),
  on(conversationsActions.addLastMessage, (state, { message }) => {
    let _conversation = state.conversations
      ? state.conversations.find((c) => c._id == message.conversation._id)
      : null;
    let _list_conversation = state.conversations
      ? state.conversations.filter((c) => c._id != message.conversation._id)
      : null;
    return {
      ...state,
      conversations:
        _conversation && _list_conversation
          ? [addLastMessage(message, _conversation), ..._list_conversation]
          : state.conversations,
    };
  }),
  on(
    conversationsActions.updateReadLastMessage,
    (state, { conversation_id, readLastMessage }) => {
      let _list_update: IConversation[] = [];
      if (readLastMessage) {
        // Si es un msj nuevo sin leer actualiza conversacion y se ubica en primer lugar del listado
        let _select_conversation = state.conversations
          ? Object.assign(
              {},
              state.conversations.find((c) => c._id == conversation_id),
            )
          : null;
        if (_select_conversation)
          _select_conversation.unreadMessages = readLastMessage;
        let _list_conversations = state.conversations
          ? state.conversations.filter((c) => c._id !== conversation_id)
          : [];
        if (_select_conversation && _list_conversations)
          _list_update = [_select_conversation, ..._list_conversations];
      } else {
        // Solo se actualiza el valor unreadMessages sin cambiar el orden del listado
        _list_update = state.conversations
          ? state.conversations.map((c) =>
              c._id == conversation_id ? { ...c, unreadMessages: false } : c,
            )
          : [];
      }
      return {
        ...state,
        conversations: _list_update,
      };
    },
  ),
  on(conversationsActions.updateLead, (state, { lead, conversation_id }) => ({
    ...state,
    conversations: state.conversations
      ? state.conversations.map((c) => {
          return c._id == conversation_id ? { ...c, lead: lead } : c;
        })
      : [],
  })),
  on(conversationsActions.setEstadoWebsocket, (state, { estado }) => ({
    ...state,
    estado: estado,
  })),
);

const addLastMessage = (message: IMessage, _conversation: IConversation) => {
  return {
    ..._conversation,
    unreadMessages: message.author != 'system',
    lastMessage: message,
  };
};
