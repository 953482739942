import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IConversation, ILead } from '../../models';
import { User } from '../../../users/models';

export const leadActions = createActionGroup({
  source: 'Lead',
  events: {
    'Set Lead': emptyProps(),
    'Get Lead': props<{ conversation: IConversation }>(),
    'Get Lead Success': props<{ lead: ILead }>(),
    'Get Lead Failure': props<{ error: string }>(),

    'Update Lead': props<{
      conversation_id: string;
      attributes: any;
      userActive: User;
    }>(),
    'Update Lead Success': props<{ lead: ILead }>(),
    'Update Lead Failure': props<{ error: string }>(),
  },
});
