import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { IAuditoria } from '../../models';

export const auditoriaActions = createActionGroup({
  source: 'auditoria',
  events: {
    'Create Auditoria': props<{ auditoria: IAuditoria }>(),
    'Create Auditoria Success': props<{ new_auditoria: IAuditoria }>(),
    'Create Auditoria Failure': props<{ error: string }>(),
    'Get Auditoria': props<{ conversation_id: string }>(),
    'Get Auditoria Success': props<{ auditorias: IAuditoria[] | null }>(),
    'Get Auditoria Failure': props<{ error: string }>(),
    'Clear Auditorias': emptyProps(),
  },
});
