<button
  class="mr-2"
  (click)="cancelAction.emit($event)"
  mat-button
  mat-dialog-close
  type="button"
>
  {{ cancelLabel | translate }}
</button>
<button (click)="submitAction.emit($event)" mat-button type="button">
  {{ submitLabel | translate }}
</button>
