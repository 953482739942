import { Component, Input } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ThemeService } from '@core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'tec-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss'],
})
export class ThemeSwitcherComponent {
  @Input()
  public showIcons = true;

  @Input()
  public classList = '';

  public isDarkThemeActive$: Observable<boolean>;
  public isLightThemeActive$: Observable<boolean>;
  constructor(private themeServices: ThemeService) {
    this.isDarkThemeActive$ = this.themeServices.darkThemeIsActive$;
    this.isLightThemeActive$ = this.themeServices.lightThemeIsActive$;
  }

  useDarkTheme({ checked }: MatSlideToggleChange): void {
    if (checked) {
      this.themeServices.useDarkTheme();
    } else {
      this.themeServices.useLightTheme();
    }
  }
}
