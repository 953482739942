import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _loading$ = new BehaviorSubject<boolean>(false);
  public readonly loading$: Observable<boolean> = this._loading$.asObservable();

  public isLoading$(): BehaviorSubject<boolean> {
    return this._loading$;
  }

  public setLoading(_value: boolean): void {
    this._loading$.next(_value);
  }
  public getLoading(): Observable<boolean> {
    return this._loading$.asObservable();
  }
}
