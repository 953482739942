import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { AppState } from '@store';
import { conversationsActions } from 'src/app/dashboard/modules/leads/store/conversations/conversations.actions';
import { conversationActiveActions } from 'src/app/dashboard/modules/leads/store/conversationActive/conversationActive.actions';

@Injectable({ providedIn: 'root' })
export class WebSocketService {
  private _socket: Socket;
  readonly uri = environment.serverSocket;
  constructor(private store: Store<AppState>) {
    this._socket = io(this.uri, {
      query: { nameRoom: 'room-leads' },
      extraHeaders: {
        token: environment.secretToken,
      },
    });
    this.listening();
  }

  listening() {
    this._socket.on('connect_error', (err: any) => {
      console.log(err instanceof Error); // true
      console.log(err.message); // not authorized
      console.log(err.data); // { content: "Please retry later" }
    });
    this._socket.on('connect', () => {
      this.store.dispatch(
        conversationsActions.setEstadoWebsocket({ estado: 'Connected' }),
      );
    });
    this._socket.on('disconnect', () => {
      this.store.dispatch(
        conversationsActions.setEstadoWebsocket({ estado: 'Disconnected' }),
      );
    });
    this._socket.on('conversationJoined', (data) => {
      this.store.dispatch(
        conversationsActions.conversationJoined({
          conversation: JSON.parse(data),
        }),
      );
    });

    this._socket.on('messageAdded', (_message: any) => {
      if (_message) {
        let message = JSON.parse(_message);
        this.store.dispatch(
          conversationActiveActions.addMessage({
            message: message,
          }),
        );
        this.store.dispatch(
          conversationsActions.addLastMessage({
            message: message,
          }),
        );
      }
    });
  }

  listen(eventName: string): Observable<any> {
    return new Observable((subscriber) => {
      this._socket.on(eventName, (data) => {
        subscriber.next(JSON.parse(data));
      });
    });
  }

  emit(event: string, payload: any) {
    this._socket.emit(event, payload);
  }

  upload(event: string, payload: any) {
    this._socket.emit(event, payload, (status: string) => {
      console.log(status);
    });
  }

  disconnect() {
    this._socket.disconnect();
  }
}
